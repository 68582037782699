const baseRequirement = {
  required: true, message: 'Please fill', trigger: 'change'
}
const BankValidation = {
  required: true, pattern: /[0-9]{2}-[0-9]{4}-[0-9]{7}-[0-9]{2,3}/, message: 'Please check your account', trigger: 'change'
}
const rules = {
  bank_name: [
    baseRequirement
  ],
  account_name: [
    baseRequirement
  ],
  account_number: [
    BankValidation
  ]
}
export default rules
