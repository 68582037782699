<template>
    <div class="bank_account_container relative">
        <input
            ref="input1"
            @keyup="handleKeyUp"
            v-model="bankChunk1"
            :disabled="disabled"
            v-enterNumber
            class="bank_input"
            id="input1"
            type="text"
            :maxlength="chunk1Size"
            :size="chunk1Size"
            @change="handleInpuChange"
        />-
        <input
            ref="input2"
            :disabled="disabled"
            @keyup="handleKeyUp"
            v-enterNumber
            v-model="bankChunk2"
            id="input2"
            class="bank_input"
            type="text"
            :maxlength="chunk2Size"
            :size="chunk2Size"
            @change="handleInpuChange"

        />-
        <input
            ref="input3"
            :disabled="disabled"
            @keyup="handleKeyUp"
            v-enterNumber
            v-model="bankChunk3"
            id="input3"
            class="bank_input"
            type="text"
            :maxlength="chunk3Size"
            @change="handleInpuChange"
            :size="chunk3Size"
        />-
        <input
            ref="input4"
            @keyup="handleKeyUp"
            :disabled="disabled"
            v-enterNumber
            v-model="bankChunk4"
            id="input4"
            class="bank_input"
            type="text"
            :maxlength="chunk4Size"
            @change="handleInpuChange"
            :size="chunk4Size"
        />
    </div>
</template>

<script>
export default {
  created () {
    const accountArray = this.account.split('-')
    if (accountArray.length >= 3) {
      this.bankChunk1 = accountArray[0]
      this.bankChunk2 = accountArray[1]
      this.bankChunk3 = accountArray[2]
      this.bankChunk4 = accountArray[3]
    }
  },
  data () {
    return {
      chunk1Size: 2,
      chunk2Size: 4,
      chunk3Size: 7,
      chunk4Size: 3,
      bankChunk1: null,
      bankChunk2: null,
      bankChunk3: null,
      bankChunk4: null
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    account: {
      type: String,
      default: '---'
    }
  },
  computed: {
    bankAccount () {
      return `${this.bankChunk1}-${this.bankChunk2}-${this.bankChunk3}-${this.bankChunk4}`
    }
  },
  methods: {
    handleInpuChange () {
      this.$emit('handleInpuChange', this.bankAccount)
    },
    handleKeyUp (e) {
      const { keyCode } = e
      const { value, maxLength, id } = e.target
      if (keyCode !== 8) {
        // keyCode: 8是删除键
        const nextId = parseInt(id.replace(/[^0-9]/ig, '')) + 1
        if (nextId < 5) {
          if (value.length === maxLength) {
            this.$refs['input' + nextId].focus()
          }
        }
      } else {
        const nextId = parseInt(id.replace(/[^0-9]/ig, '')) - 1
        if (nextId > 0) {
          if (value.length === 0) {
            this.$refs['input' + nextId].focus()
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.bank_input {
    height: 32px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s;
    padding: 4px 11px;
    outline: none;
    font-weight: 500;
}
.bank_input:disabled{
  background-color: #f5f5f5;
  color: rgba(0,0,0,.25);
  cursor:not-allowed
}
.bank_input:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
</style>
